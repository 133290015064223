<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="">
        <div class="">
          <label class="txt-right required">Tên chành xe</label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="freight.name"
            :autoResize="true"
            rows="1"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="name" />
          <!-- </div> -->
        </div>
        <div class="mt-3">
          <label class="txt-right">Mã chành</label>
          <!-- <div class="p-col"> -->
          <InputText
            required
            v-model="freight.code"
            id="code"
            type="text"
            class="text-box input-text-box disabled"
            disabled
          />
          <!-- </div> -->
        </div>
        <div>
          <label class="txt-right required pt-3">Quốc gia</label>
          <Dropdown
            v-model="freight.country_id"
            :options="country_list"
            optionLabel="name"
            optionValue="id"
            placeholder="Chọn"
            class="text-box"
            @change="loadStateList(freight.country_id)"
          />
        </div>
        <div>
          <label class="txt-right required pt-3">Tỉnh/ Thành phố</label>
          <Dropdown
            v-model="freight.state_id"
            :options="state_list"
            optionLabel="name"
            optionValue="id"
            placeholder="Chọn"
            class="text-box"
            @change="loadDistrictList(freight.state_id)"
          />
          <input class="input_tmp_validator" id="state" />
        </div>
        <div>
          <label class="txt-right required pt-3">Quận/ Huyện</label>
          <Dropdown
            v-model="freight.district_id"
            :options="district_list"
            optionLabel="name"
            optionValue="id"
            placeholder="Chọn"
            class="text-box"
            @change="loadWardList(freight.district_id)"
          />
          <input class="input_tmp_validator" id="district" />
        </div>
        <div>
          <label class="txt-right pt-3">Phường/ Xã</label>
          <Dropdown
            v-model="freight.ward_id"
            :options="ward_list"
            optionLabel="name"
            optionValue="id"
            placeholder="Chọn"
            class="text-box"
          />
          <input class="input_tmp_validator" id="ward" />
        </div>
        <div>
          <label class="txt-right pt-3">Số nhà, tên đường</label>
          <Textarea
            v-model="freight.street"
            :autoResize="true"
            rows="1"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="street" />
        </div>
        <div class="flex justify-content-center pt-3">
          <Checkbox v-model="freight.active" :binary="true" />Hoạt động
        </div>
        <!-- </div> -->
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning return-style-button"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
// import { HotModuleReplacementPlugin } from "webpack";
import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  data() {
    return {
      freight: {
        name: "",
        code: "",
        country_id: 1,
        state_id: null,
        district_id: null,
        ward_id: null,
        street: "",
        active: true,
      },
      lastFreight: "",
      country_list: [],
      state_list: [],
      district_list: [],
      ward_list: [],
      code: "",
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role: [],
      saving: false,
      addressData: {
        street_number: "251",
        route: "Quang Trung",
        administrative_area_level_1: "Thành phố Hồ Chí Minh",
        country: "Việt Nam",
        latitude: 10.8286588,
        longitude: 106.6732573,
      },
      placeResultData: {
        address_components: [
          { long_name: "251", short_name: "251", types: ["street_number"] },
          {
            long_name: "Quang Trung",
            short_name: "Quang Trung",
            types: ["route"],
          },
          {
            long_name: "Gò Vấp",
            short_name: "Gò Vấp",
            types: ["administrative_area_level_2", "political"],
          },
          {
            long_name: "Thành phố Hồ Chí Minh",
            short_name: "Thành phố Hồ Chí Minh",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "Việt Nam",
            short_name: "VN",
            types: ["country", "political"],
          },
        ],
        adr_address:
          '<span class="street-address">251 Quang Trung</span>, <span class="extended-address">Phường 10</span>, <span class="locality">Gò Vấp</span>, <span class="region">Thành phố Hồ Chí Minh</span>, <span class="country-name">Việt Nam</span>',
        formatted_address:
          "251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
        geometry: {
          location: { lat: 10.8286588, lng: 106.6732573 },
          viewport: {
            south: 10.8273459697085,
            west: 106.6719226697085,
            north: 10.8300439302915,
            east: 106.6746206302915,
          },
        },

        icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
        name: "251 Quang Trung",
        place_id: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        plus_code: {
          compound_code: "RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
          global_code: "7P28RMHF+F8",
        },
        reference: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        types: ["street_address"],
        url: "https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a",
        utc_offset: 420,
        html_attributions: [],
        utc_offset_minutes: 420,
      },
    };
  },
  async mounted() {
    // var that = this;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model", this.model);
      (this.freight.name = this.model.name),
        (this.freight.code = this.model.code),
        await this.loadCountryList(),
        console.log("COMPARE", this.freight.country_id, this.model.country_id),
        (this.freight.country_id = this.model.country_id),
        console.log("UPDATE Country", this.freight.country_id);
      await this.loadStateList(this.model.country_id),
        (this.freight.state_id = this.model.state_id),
        console.log("UPDATE State", this.freight.state_id);

      await this.loadDistrictList(this.model.state_id),
        (this.freight.district_id = this.model.district_id),
        console.log("UPDATE District", this.freight.district_id);

      await this.loadWardList(this.model.district_id),
        (this.freight.ward_id = this.model.ward_id),
        (this.freight.street = this.model.street),
        (this.freight.active = this.model.active);
    } else {
      await this.loadCountryList();
      this.freight.country_id = 1;
      await this.loadStateList(this.freight.country_id);
    }
    this.listGroupRole().then((res) => {
      console.log("resres", res);
      this.list_group_role = res;
    });
    console.log("AAAAAAAAAAAA");
  },
  methods: {
    concatenateAddress() {
      var address = "";
      if (!this.$commonFuction.isEmpty(this.freight.street))
        address += this.freight.street ;
      if (!this.$commonFuction.isEmpty( this.freight.ward_id))
        if (!this.$commonFuction.isEmpty(address)) {
          address +=", ";
        }
      console.log("this.freight.ward_id:",this.freight.ward_id)
        var get_ward = this.ward_list.find(
            (x) => x.id == this.freight.ward_id
        )
        address += (get_ward) ? get_ward.name:'';
      if (!this.$commonFuction.isEmpty(this.freight.district_id))
        if (!this.$commonFuction.isEmpty(address)) {
          address +=", ";
        }
        address += this.district_list.find(
          (x) => x.id == this.freight.district_id
        ).name;
      if (!this.$commonFuction.isEmpty(this.freight.state_id))
        if (!this.$commonFuction.isEmpty(address)) {
          address +=", ";
        }
        address += this.state_list.find(
          (x) => x.id == this.freight.state_id
        ).name;
      if (!this.$commonFuction.isEmpty(this.freight.country_id))
        if (!this.$commonFuction.isEmpty(address)) {
          address +=", ";
        }
        address += this.country_list.find(
          (x) => x.id == this.freight.country_id
        ).name;
      console.log("DIA CHIIIII", address);
      return address;
    },
    async getLastFreight() {
      /*var queryLastFreight = gql`
        query getLastFreight {
          freight(order_by: { created_at: desc }, limit: 1) {
            id
            name
            code
          }
        }
      `;
      console.log("queryLastFreight:",queryLastFreight)
      var callLastFreight = await this.$apollo.query({
        query: queryLastFreight,
      });*/

      var where = {};
      var order_by = { created_at: "desc" };
      var callLastFreight = await this.$CoreService.getListData(
        "freight",
        where,
        0,
        1,
        order_by
      );

      console.log("ALOGLOG", callLastFreight);
      if (callLastFreight.length > 0) {
        this.lastFreight = callLastFreight[0].code;
        console.log("LastFreight", this.lastFreight);
      } else this.lastFreight = "CX000000";
    },
    async getFreightCode() {
      await this.getLastFreight();

      let num = parseInt(this.lastFreight.substring(2), 10);
      num++;
      console.log("HOT", num);
      var code = num.toString();
      console.log("CODECODEXXXXXXXXX", code);
      while (code.length < 6) code = "0" + code;
      code = "CX" + code;
      return code;
    },
    async loadCountryList() {
      var query_country = gql`
        query getCountry {
          country(where: { active: { _eq: true }, deleted: { _eq: false } }) {
            id
            name
          }
        }
      `;
      var res_country = await this.$apollo.query({
        query: query_country,
      });
      this.country_list = res_country.data.country;
      this.freight.country_id = null;
      this.freight.state_id = null;
      this.freight.district_id = null;
      this.freight.ward_id = null;
      console.log("RESETTTT");
    },
    async loadStateList(idCountry) {
      var query_state = gql(`
      query getState{
        state(where: {country_id: {_eq: ${idCountry}}, active: {_eq: true}, deleted: {_eq: false}}) {
          id
          name
        }
      }
    `);
      var res_state = await this.$apollo.query({
        query: query_state,
      });
      this.state_list = res_state.data.state;
      this.freight.state_id = null;
      this.freight.district_id = null;
      this.freight.ward_id = null;
    },
    async loadDistrictList(idState) {
      var query_district = gql(`
      query getDistrict{
        district(where: {state_id: {_eq: ${idState}}, active: {_eq: true}, deleted: {_eq: false}}) {
          id
          name
        }
      }
    `);
      var res_district = await this.$apollo.query({
        query: query_district,
      });
      this.district_list = res_district.data.district;
      this.freight.district_id = null;
      this.freight.ward_id = null;
    },
    async loadWardList(idDistrict) {
      var query_ward = gql(`
      query getWard{
        ward(where: {district_id: {_eq: ${idDistrict}}, active: {_eq: true}, deleted: {_eq: false}}) {
          id
          name
        }
      }
    `);
      var res_ward = await this.$apollo.query({
        query: query_ward,
      });
      this.ward_list = res_ward.data.ward;
      this.freight.ward_id = null;
    },
    async listGroupRole() {
      var where = {
        active: { _eq: true },
        deleted: { _eq: false },
      };
      var order_by = { id: "desc" };
      var listData = await this.$CoreService.getListData(
        "group",
        where,
        null,
        null,
        order_by
      );
      return listData;
    },
    async getGroupUser(uid) {
      var table_cer = "group_user";
      var where = { uid: { _eq: uid } };
      var gqLQueryList = await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
        },
      });
      var listData = resData.data[table_cer];
      return listData;
    },

    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      this.$emit("back");
    },
    async validateData() {
      this.validationErrors = {};

      let name_add = document.getElementById("name");
      if (
        this.freight.name == undefined ||
        this.freight.name == null ||
        this.freight.name == ""
      ) {
        name_add.setCustomValidity("Tên chành xe không được để trống!");
      } else if (this.freight.name.length > 255) {
        name_add.setCustomValidity("Tên chành xe không được quá 255 ký tự!");
      } else {
        name_add.setCustomValidity("");
      }
      console.log("AAABBBCCC", this.freight);
      let state_add = document.getElementById("state");
      if (this.$commonFuction.isEmpty(this.freight.state_id)) {
        state_add.setCustomValidity("Tỉnh/ Thành phố không được để trống!");
      } else {
        state_add.setCustomValidity("");
      }

      let district_add = document.getElementById("district");
      if (
        this.freight.district_id == undefined ||
        this.freight.district_id == null ||
        this.freight.district_id == ""
      ) {
        district_add.setCustomValidity("Quận/ Huyện không được để trống!");
      } else {
        district_add.setCustomValidity("");
      }

      let street_add = document.getElementById("street");
      if (this.freight.street.length > 255) {
        street_add.setCustomValidity(
          "Số nhà, tên đường không được quá 255 ký tự!"
        );
      } else {
        street_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        console.log("SHIBABABA");
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      // console.log("AAAAAAANOOOO");
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        console.log("FAILEEDDDDD");
        this.$commonFuction.is_loading(false);
        return;
      }
      // console.log("AFTER VALIDATE");
      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);

      // if (!this.account_exist) {
      // if ((this.freight.code = "")) {
      //   this.freight.code = data.stt;
      // }
      if (this.mode == "add") {
        this.freight.code = await this.getFreightCode();
        console.log(
          "THISSSS ISSS VBEEEEEEEEEEEEEEEEEEEEEEEE",
          this.freight.code
        );
      }
      console.log("AAAAAAAAAAAAAAAAAA", this.mode);
      var params = {
        name: this.freight.name,
        code: this.freight.code,
        country_id: this.freight.country_id,
        state_id: this.freight.state_id,
        district_id: this.freight.district_id,
        ward_id: this.freight.ward_id,
        street: this.freight.street,
        active: this.freight.active,
        address: this.concatenateAddress(),
      };
      console.log("saveData", params);
      this.$emit("save", params);
      // }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        let acceptTags = [
          "jpeg",
          "jpg",
          "gif",
          "png",
          "JPEG",
          "JPG",
          "PNG",
          "GIF",
        ];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Please upload the image file in the following format: jpeg, jpg, gif, png"
            );
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [],
            arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }

          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === "") {
              this.imagesDeletes.push(this.images[0]);
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData();
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === "") this.imagesDeletes.push(img);
    },
  },
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}
.required:after {
  content: " *";
  color: red;
}

.disabled {
  background-color: #eeeeee;
}
.vs__dropdown-option.vs__dropdown-option--selected,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #c40380 !important;
  color: #ffffff;
}
.p-checkbox {
  margin-right: 1rem !important;
}
</style>
