<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--<div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Config from "../../../config/index";
import DataServices from "@/core/DataServices";
import formEdit from "./add";
import moment from "moment-timezone";
//import firebase from "../../../../firebase.json";
export default {
  components: {
    formEdit,
  },
  data() {
    return {
      validationErrors: [],
      title: "Thêm Mới Chành Xe",
      mode: "add",
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction: null,
    };
  },
  async mounted() {
    console.log("this.$route.name:", this.$route.name);
    this.mode = this.$route.name === "editfreight" ? "edit" : this.mode;
    this.title = this.mode === "edit" ? "Chỉnh sửa chành xe" : this.title;
    if (this.$route.params.id !== undefined)
      await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(
      this.$constants.KEY_MENU.LIST_FREIGHT
    );
    if (this.mode == "edit") {
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    } else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: "/admin/list-freight" });
    },
    convertPhone(numberPhone) {
      var phone = numberPhone;
      if (numberPhone[0] === "0") {
        phone = phone.substring(1);
      }
      phone = phone.replace(" ", "");
      phone = "+84" + phone;
      return phone;
    },
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {
        var uid = this.$store.getters.user;
        var objects = {
          name: params.name,
          code: params.code,
          country_id: params.country_id,
          state_id: params.state_id,
          district_id: params.district_id,
          // ward_id: params.ward_id,
          street: params.street,
          address: params.address,
          active: params.active,
          updated_by: uid,
          updated_at: moment(new Date()).tz("Asia/Ho_Chi_Minh").toDate(),
        };
        if (params.ward_id) objects.ward_id = params.ward_id;
        console.log("loLOLOLOLOLO");
        console.log(objects);
        var that = this;
        if (this.mode === "add") {
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
          await that.$apollo
            .mutate({
              mutation: DataServices.insertData("freight"),
              variables: {
                objects: dataInsert,
              },
            })
            .then((res) => {
              console.log("Thành công");
              console.log(res); //
              this.$commonFuction.is_loading(false);
              that.$toast.add({
                severity: "success",
                summary: "Thông báo",
                detail: "Thêm thành công",
                life: 3000,
              });
              this.$router.push({ path: "/admin/list-freight" });
            })
            .catch(async (error) => {
              console.log("error", error);
              that.$toast.add({
                severity: "error",
                summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000,
              });
              this.$commonFuction.is_loading(false);
              // this.summitEvent = false;
            });
        } else {
          // var user_id = this.$route.params.id;
          let variables = {
            data_update: objects,
            where_key: {
              id: { _eq: this.models.id },
            },
          };
          await this.$apollo
            .mutate({
              mutation: DataServices.updateData("freight"),
              variables: variables,
            })
            .then(async (res) => {
              console.log("Thành công");
              console.log(res); //
              this.$commonFuction.is_loading(false);
              that.$toast.add({
                severity: "success",
                summary: "Thông báo",
                detail: "Thêm thành công",
                life: 3000,
              });
              this.$router.push({ path: "/admin/list-freight" });
            })
            .catch(async (error) => {
              console.log("error", error);
              that.$toast.add({
                severity: "error",
                summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000,
              });
              this.$commonFuction.is_loading(false);
              // this.summitEvent = false;
            });
        }
      }
    },
    async saveListCategory(userID, list, type = "add") {
      if (type == "add") {
        var list_add = [];
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          list_add.push({ uid: userID, category_id: el.id });
        }
        if (list_add.length > 0) {
          var query_insert = DataServices.insertData("users_category");
          var variables_insert = {
            objects: list_add,
          };
          await this.$apollo
            .mutate({
              mutation: query_insert,
              variables: variables_insert,
            })
            .then(() => {});
        }
      } else if (type == "del") {
        let query = `mutation Mymutation($_in: [bigint!] = "", $_eq: uuid = "") {
              delete_users_category(where: {_and: {category_id: {_in: $_in}, uid: {_eq: $_eq}}}) {
                affected_rows
              }
            }`;
        var list_del = [];
        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          list_del.push(element.id);
        }
        if (list_del.length > 0) {
          let variables_del = {
            _in: list_del,
            _eq: userID,
          };
          await this.$apollo
            .mutate({
              mutation: gql(query),
              variables: variables_del,
            })
            .then(() => {});
        }
      }
    },
    async uploadImagesOld(userID) {
      var s3client = Config.s3Client("user");
      if (this.images && userID) {
        //xoá avatar
        if (this.oldImages.length > 0) await this.deleteAvatar(s3client);

        this.$store.commit("setLoading", true);
        var objects = null;
        for (let index = 0; index < this.images.length; index++) {
          const img = this.images[index];
          let file = img.source;
          if (file !== "" && img.file !== "") {
            let fileName = img.name;
            let random = Math.random().toString().slice(2);
            fileName = `${random}_${fileName}`;
            await s3client.uploadFile(file, fileName).then((data) => {
              objects = data.location;
            });
          }
        }
        // console.log(objects);
        if (objects !== null) {
          this.$apollo
            .mutate({
              mutation: DataServices.updateData("users"),
              variables: {
                data_update: {
                  avatar: objects,
                },
                where_key: {
                  id: { _eq: userID },
                },
              },
            })
            .then(() => {
              // console.log('UPDATE AVATAR THANH CONG')
            })
            .catch((error) => {
              console.error("ERROR: ", error);
            });
        }
      }
    },
    async deleteAvatar(s3client) {
      for (let i = 0; i < this.oldImages.length; i++) {
        let fileName = this.oldImages[i].name;
        await s3client.deleteFile(fileName);
      }
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList("freight"),
        variables: {
          where_key: {
            id: { _eq: id },
          },
        },
        fetchPolicy: "network-only",
      });
      console.log("datadata:", data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      // console.log(
      //   "SHIBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",
      //   this.models.name
      // );
      this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only {
      padding: 0 !important;
    }
  }
}
.note {
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 24px;
  padding-top: 10px;
}
.p-formgrid {
  margin: 0;
  .p-panel-header {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    background: #eeeeee;
  }
  .p-card-field {
    padding: 24px;
    .p-field {
      .p-col-fixed {
        width: 300px;
        display: inline-block !important;
        margin: 0;
      }
      .p-col-width {
        width: 500px !important;
        border-radius: 5px;
        &.disabled {
          background: #eeeeee;
          border: 0 none;
          padding: 8px 10px;
        }
        .file-select {
          margin: 0;
          input[type="file"] {
            display: none;
          }
          .select-button {
            display: inline-block;
            cursor: pointer;
            background: transparent;
            border-radius: 5px;
            color: #000000;
            font-size: 13px;
            padding: 3px 16px;
            border: 1px solid #b4b4b4;
          }
        }
        &.pac-target-input {
          height: 80px;
          word-break: break-all;
          overflow-wrap: break-word;
        }
      }
      .box-images {
        margin-top: 10px;
        position: relative;
        .item-img {
          height: 150px;
          margin-right: 8px;
          margin-bottom: 8px;
          display: inline-flex;
          position: relative;
          border: 0.5px solid #ccc;
          &.p-col-5 {
            width: 18%;
          }
          img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            object-fit: contain;
          }
          .button-clear {
            width: 18px;
            height: 18px;
            background: #f5564e;
            float: right;
            cursor: pointer;
            position: absolute;
            right: 0px;
            img {
              vertical-align: top;
            }
          }
        }
      }
      .p-invalid {
        display: block;
      }
    }
  }
  .fixed-bottom-toolbar {
    background: transparent;
    border: 0 none;
    margin-top: 22px;
    border-top: 1px solid #b4b4b4;
    padding: 0;
    padding-top: 30px;
    .p-button {
      width: auto;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
       display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 50px;
}
.box-check-box {
  position: relative;
}
.box-check-box label {
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day {
  position: absolute;
  right: 0px;
  top: 2px;
}
</style>
